<template>
  <div>
    <v-row align="center" class="mb-4">
      <v-col cols="auto" class="pr-12">
        <div class="d-flex align-end">
          <h2>Costs</h2>
          <v-chip class="ml-4"
            >Total Costs: {{ itinerary.finance.total_costs }}</v-chip
          >
          <v-chip class="ml-4"
            >Total Paid: {{ itinerary.finance.total_cost_payments }}</v-chip
          >
          <v-chip class="ml-4"
            >Outstanding: {{ itinerary.finance.outstanding_costs }}</v-chip
          >
        </div>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn
            small
            depressed
            color="green white--text"
            @click="$refs.costForm.openForm()"
          >
            <v-icon small left>mdi-plus</v-icon>Add Cost
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-expansion-panels multiple focusable :value="[0, 1, 2, 3]">
      <v-expansion-panel>
        <v-expansion-panel-header>Accommodation Costs</v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          <v-sheet outlined>
            <v-data-table
              :headers="tables.accommodation"
              :items="accommodationCosts"
              show-expand
              disabled-pagination
              hide-default-footer
              no-data-text="No costs for accommodation"
            >
              <template v-slot:item.twin_rate="{ item }"
                >£{{ item.cost.twin_rate }}</template
              >
              <template v-slot:item.single_rate="{ item }"
                >£{{ item.cost.single_rate }}</template
              >
              <template v-slot:item.cost.total="{ item }"
                >£{{ item.cost.total }}</template
              >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      @click="$refs.costForm.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      class="ml-2"
                      @click="$refs.deleteCost.open(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="tables.accommodation.length">
                  <div class="pt-4 pb-4">
                    <v-row>
                      <v-col cols="4">
                        <v-checkbox
                          label="Booking Made"
                          v-model="item.cost.booking_made"
                          hide-details
                          readonly
                          class="mt-0"
                        ></v-checkbox>
                        <v-checkbox
                          label="Booking Confirmed"
                          v-model="item.cost.booking_confirmed"
                          hide-details
                          readonly
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="4">
                        <div class="mb-3">
                          <div class="font-weight-bold">Deposit Due</div>
                          <div>£{{ item.cost.deposit_amount }}</div>
                        </div>
                        <div>
                          <div class="font-weight-bold">Deposit Paid Date</div>
                          <div>
                            <span
                              v-if="item.cost.date_deposit_due"
                              v-html="item.cost.date_deposit_due"
                            ></span>
                            <span v-else class="text--disabled">Not Paid</span>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="mb-3">
                          <div class="font-weight-bold">Balance Due</div>
                          <div>£{{ item.cost.balance_due }}</div>
                        </div>
                        <div>
                          <div class="font-weight-bold">Balance Due Date</div>
                          <div>
                            <span
                              v-if="item.cost.date_balance_due"
                              v-html="item.cost.date_balance_due"
                            ></span>
                            <span v-else class="text--disabled"
                              >Not Due Yet</span
                            >
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-3"></v-divider>
                    <v-row align="center">
                      <v-col cols="auto" class="pr-12">
                        <h3>Payments</h3>
                      </v-col>
                      <v-col>
                        <v-row align="center" justify="end" no-gutters>
                          <v-btn
                            depressed
                            color="accent"
                            small
                            @click="$refs.costPaymentForm.openForm(item.id)"
                          >
                            <v-icon small left>mdi-plus</v-icon> Add Payment
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-simple-table v-if="item.payments.length > 0">
                      <thead>
                        <tr>
                          <th>Date Paid</th>
                          <th>Amount Paid</th>
                          <th>Description</th>
                          <th class="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="payment in item.payments"
                          v-bind:key="payment.id"
                        >
                          <td>{{ payment.formatted_dates.date }}</td>
                          <td>£{{ payment.amount }}</td>
                          <td>{{ payment.description }}</td>
                          <td class="text-right">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  x-small
                                  depressed
                                  color="blue lighten-4 blue--text"
                                  class="mr-2"
                                  v-on="on"
                                  @click="
                                    $refs.costPaymentForm.openForm(
                                      item.id,
                                      payment
                                    )
                                  "
                                >
                                  <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  x-small
                                  depressed
                                  color="red lighten-4 red--text"
                                  v-on="on"
                                  @click="$refs.deletePayment.open(payment)"
                                >
                                  <v-icon x-small>mdi-archive</v-icon>
                                </v-btn>
                              </template>
                              <span>Archive</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <p v-else>No Payments Found</p>
                  </div>
                </td>
              </template>
              <template v-slot:body.append>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <th>Sum Total</th>
                  <td class="text-right">£{{ accommodationTotal }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-sheet>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Golf Costs</v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          <v-sheet outlined>
            <v-data-table
              :headers="tables.course"
              :items="courseCosts"
              show-expand
              no-data-text="There are no golfing costs"
            >
              <template v-slot:item.cost.fee="{ item }">
                £{{ item.cost.fee }}
              </template>
              <template v-slot:item.cost.total="{ item }">
                £{{ item.cost.total }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      @click="$refs.costForm.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      class="ml-2"
                      @click="$refs.deleteCost.open(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="tables.accommodation.length">
                  <div class="pt-4 pb-4">
                    <v-row>
                      <v-col cols="4">
                        <v-checkbox
                          label="Booking Made"
                          v-model="item.cost.booking_made"
                          hide-details
                          readonly
                          class="mt-0"
                        ></v-checkbox>
                        <v-checkbox
                          label="Booking Confirmed"
                          v-model="item.cost.booking_confirmed"
                          hide-details
                          readonly
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="4">
                        <div class="mb-3">
                          <div class="font-weight-bold">Deposit Due</div>
                          <div>£{{ item.cost.deposit_amount }}</div>
                        </div>
                        <div>
                          <div class="font-weight-bold">Deposit Paid Date</div>
                          <div>
                            <span
                              v-if="item.cost.date_deposit_due"
                              v-html="item.cost.date_deposit_due"
                            ></span>
                            <span v-else class="text--disabled">Not Paid</span>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="mb-3">
                          <div class="font-weight-bold">Balance Due</div>
                          <div>£{{ item.cost.balance_due }}</div>
                        </div>
                        <div>
                          <div class="font-weight-bold">Balance Due Date</div>
                          <div>
                            <span
                              v-if="item.cost.date_balance_due"
                              v-html="item.cost.date_balance_due"
                            ></span>
                            <span v-else class="text--disabled"
                              >Not Due Yet</span
                            >
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-3"></v-divider>
                    <v-row align="center">
                      <v-col cols="auto" class="pr-12">
                        <h3>Payments</h3>
                      </v-col>
                      <v-col>
                        <v-row align="center" justify="end" no-gutters>
                          <v-btn
                            depressed
                            color="accent"
                            small
                            @click="$refs.costPaymentForm.openForm(item.id)"
                          >
                            <v-icon small left>mdi-plus</v-icon> Add Payment
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-simple-table v-if="item.payments.length > 0">
                      <thead>
                        <tr>
                          <th>Date Paid</th>
                          <th>Amount Paid</th>
                          <th>Description</th>
                          <th class="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="payment in item.payments"
                          v-bind:key="payment.id"
                        >
                          <td>{{ payment.formatted_dates.date }}</td>
                          <td>£{{ payment.amount }}</td>
                          <td>{{ payment.description }}</td>
                          <td class="text-right">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  x-small
                                  depressed
                                  color="blue lighten-4 blue--text"
                                  class="mr-2"
                                  v-on="on"
                                  @click="
                                    $refs.costPaymentForm.openForm(
                                      item.id,
                                      payment
                                    )
                                  "
                                >
                                  <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  x-small
                                  depressed
                                  color="red lighten-4 red--text"
                                  v-on="on"
                                  @click="$refs.deletePayment.open(payment)"
                                >
                                  <v-icon x-small>mdi-archive</v-icon>
                                </v-btn>
                              </template>
                              <span>Archive</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <p v-else>No Payments Found</p>
                  </div>
                </td>
              </template>
              <template v-slot:body.append>
                <tr>
                  <td></td>
                  <td></td>
                  <th>Sum Total</th>
                  <td class="text-right">£{{ courseTotal }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-sheet>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header
          >Transportation Costs</v-expansion-panel-header
        >
        <v-expansion-panel-content class="pt-4">
          <v-sheet outlined>
            <v-data-table
              :headers="tables.transportation"
              :items="transportationCosts"
              show-expand
              no-data-text="No costs for transportation"
            >
              <template v-slot:item.cost.rate="{ item }"
                >£{{ item.cost.rate }}</template
              >
              <template v-slot:item.cost.total="{ item }"
                >£{{ item.cost.total }}</template
              >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      @click="$refs.costForm.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      class="ml-2"
                      @click="$refs.deleteCost.open(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="tables.transportation.length">
                  <div class="pt-4 pb-4">
                    <v-row align="center">
                      <v-col cols="auto" class="pr-12">
                        <h3>Payments</h3>
                      </v-col>
                      <v-col>
                        <v-row align="center" justify="end" no-gutters>
                          <v-btn
                            depressed
                            color="accent"
                            small
                            @click="$refs.costPaymentForm.openForm(item.id)"
                          >
                            <v-icon small left>mdi-plus</v-icon> Add Payment
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-simple-table v-if="item.payments.length > 0">
                      <thead>
                        <tr>
                          <th>Date Paid</th>
                          <th>Amount Paid</th>
                          <th>Description</th>
                          <th class="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="payment in item.payments"
                          v-bind:key="payment.id"
                        >
                          <td>{{ payment.formatted_dates.date }}</td>
                          <td>£{{ payment.amount }}</td>
                          <td>{{ payment.description }}</td>
                          <td class="text-right">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  x-small
                                  depressed
                                  color="blue lighten-4 blue--text"
                                  class="mr-2"
                                  v-on="on"
                                  @click="
                                    $refs.costPaymentForm.openForm(
                                      item.id,
                                      payment
                                    )
                                  "
                                >
                                  <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  x-small
                                  depressed
                                  color="red lighten-4 red--text"
                                  v-on="on"
                                  @click="$refs.deletePayment.open(payment)"
                                >
                                  <v-icon x-small>mdi-archive</v-icon>
                                </v-btn>
                              </template>
                              <span>Archive</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <p v-else>No Payments Found</p>
                  </div>
                </td>
              </template>
              <template v-slot:body.append>
                <tr>
                  <td></td>
                  <td></td>
                  <th>Sum Total</th>
                  <td class="text-right">£{{ transportationTotal }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-sheet>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Other Costs</v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          <v-sheet outlined>
            <v-data-table
              :headers="tables.other"
              :items="otherCosts"
              show-expand
              no-data-text="No costs for other"
            >
              <template v-slot:item.cost.rate="{ item }"
                >£{{ item.cost.rate }}</template
              >
              <template v-slot:item.cost.total="{ item }"
                >£{{ item.cost.total }}</template
              >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      @click="$refs.costForm.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      class="ml-2"
                      @click="$refs.deleteCost.open(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="tables.other.length">
                  <div class="pt-4 pb-4">
                    <v-row align="center">
                      <v-col cols="auto" class="pr-12">
                        <h3>Payments</h3>
                      </v-col>
                      <v-col>
                        <v-row align="center" justify="end" no-gutters>
                          <v-btn
                            depressed
                            color="accent"
                            small
                            @click="$refs.costPaymentForm.openForm(item.id)"
                          >
                            <v-icon small left>mdi-plus</v-icon> Add Payment
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-simple-table v-if="item.payments.length > 0">
                      <thead>
                        <tr>
                          <th>Date Paid</th>
                          <th>Amount Paid</th>
                          <th>Description</th>
                          <th class="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="payment in item.payments"
                          v-bind:key="payment.id"
                        >
                          <td>{{ payment.formatted_dates.date }}</td>
                          <td>£{{ payment.amount }}</td>
                          <td>{{ payment.description }}</td>
                          <td class="text-right">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  x-small
                                  depressed
                                  color="blue lighten-4 blue--text"
                                  class="mr-2"
                                  v-on="on"
                                  @click="
                                    $refs.costPaymentForm.openForm(
                                      item.id,
                                      payment
                                    )
                                  "
                                >
                                  <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  x-small
                                  depressed
                                  color="red lighten-4 red--text"
                                  v-on="on"
                                  @click="$refs.deletePayment.open(payment)"
                                >
                                  <v-icon x-small>mdi-archive</v-icon>
                                </v-btn>
                              </template>
                              <span>Archive</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <p v-else>No Payments Found</p>
                  </div>
                </td>
              </template>
              <template v-slot:body.append>
                <tr>
                  <td></td>
                  <td></td>
                  <th>Sum Total</th>
                  <td class="text-right">£{{ otherTotal }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-sheet>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <CostForm ref="costForm" />
    <CostPaymentForm ref="costPaymentForm" />
    <DeletePayment ref="deletePayment" />
    <DeleteCost ref="deleteCost" />
  </div>
</template>

<script>
import CostForm from "../../components/CostForm/MainForm";
import CostPaymentForm from "../../components/CostPaymentForm";
import DeletePayment from "./components/DeletePayment";
import DeleteCost from "./components/DeleteCost";

export default {
  components: {
    CostForm,
    CostPaymentForm,
    DeletePayment,
    DeleteCost,
  },

  data() {
    return {
      tables: {
        accommodation: [
          { text: "Description", value: "cost.description" },
          { text: "Rate P/P Twin", value: "twin_rate" },
          { text: "No. of Twin", value: "cost.no_sharing" },
          { text: "Rate P/P Single", value: "single_rate" },
          { text: "No. of Single", value: "cost.no_of_singles" },
          { text: "No. of Nights", value: "cost.no_of_nights" },
          {
            text: "Total",
            value: "cost.total",
            sortable: false,
            align: "right",
          },
          { text: "", value: "actions", sortable: false, align: "right" },
          { text: "", value: "data-table-expand" },
        ],
        course: [
          { text: "Description", value: "cost.description" },
          { text: "Green Fees P/P", value: "cost.fee" },
          { text: "No. Of Golfers", value: "cost.quantity" },
          {
            text: "Total",
            value: "cost.total",
            sortable: false,
            align: "right",
          },
          { text: "", value: "actions", sortable: false, align: "right" },
          { text: "", value: "data-table-expand" },
        ],
        transportation: [
          { text: "Provider", value: "cost.supplier.name" },
          { text: "Day Rate", value: "cost.rate" },
          { text: "No. of Days", value: "cost.quantity" },
          {
            text: "Total",
            value: "cost.total",
            sortable: false,
            align: "right",
          },
          { text: "", value: "actions", sortable: false, align: "right" },
          { text: "", value: "data-table-expand" },
        ],
        other: [
          { text: "Title", value: "cost.title" },
          { text: "Single Rate", value: "cost.rate" },
          { text: "Quantity", value: "cost.quantity" },
          {
            text: "Total",
            value: "cost.total",
            sortable: false,
            align: "right",
          },
          { text: "", value: "actions", sortable: false, align: "right" },
          { text: "", value: "data-table-expand" },
        ],
      },
      loadingChanges: null,
    };
  },

  computed: {
    itinerary() {
      return this.$store.state.drum.tours["itinerary"];
    },
    accommodationCosts() {
      if (this.itinerary.costs) {
        return this.itinerary.costs.filter(
          (c) => c.cost.type === "accommodation"
        );
      }
      return [];
    },
    courseCosts() {
      if (this.itinerary.costs) {
        return this.itinerary.costs.filter((c) => c.cost.type === "golf");
      }
      return [];
    },
    transportationCosts() {
      if (this.itinerary.costs) {
        return this.itinerary.costs.filter((c) => c.cost.type === "transport");
      }
      return [];
    },
    otherCosts() {
      if (this.itinerary.costs) {
        return this.itinerary.costs.filter((c) => c.cost.type === "other");
      }
      return [];
    },
    accommodationTotal() {
      return Number.parseFloat(
        this.accommodationCosts.reduce((total, c) => {
          return total + parseFloat(c.cost.total.replace(",", ""));
        }, 0)
      ).toFixed(2);
    },
    courseTotal() {
      return Number.parseFloat(
        this.courseCosts.reduce((total, c) => {
          return total + parseFloat(c.cost.total.replace(",", ""));
        }, 0)
      ).toFixed(2);
    },
    transportationTotal() {
      return Number.parseFloat(
        this.transportationCosts.reduce((total, c) => {
          return total + parseFloat(c.cost.total.replace(",", ""));
        }, 0)
      ).toFixed(2);
    },
    otherTotal() {
      return Number.parseFloat(
        this.otherCosts.reduce((total, c) => {
          return total + parseFloat(c.cost.total.replace(",", ""));
        }, 0)
      ).toFixed(2);
    },
  },
};
</script>
