<template>
  <v-card outlined elevation="1">
    <v-card-title>Transportation</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            label="Transport Provider"
            v-model="fields.supplier_id"
            :items="suppliers"
            item-value="id"
            item-text="name"
            outlined
            hide-details
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Day Rate"
            v-model="fields.rate"
            prepend-inner-icon="mdi-currency-gbp"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Number of Days"
            v-model="fields.quantity"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      providers: [
        {
          id: 1,
          name: "Moffat & Williamson",
        },
        {
          id: 2,
          name: "St Andrews Executive Travel",
        },
      ],
      fields: {
        supplier_id: null,
        rate: 0,
        quantity: 0,
      },
    };
  },

  computed: {
    suppliers() {
      return this.$store.getters["drum/admin/transportSuppliers"];
    },
  },

  methods: {
    setFields: function (cost) {
      this.fields.supplier_id = cost.supplier_id;
      this.fields.rate = cost.rate;
      this.fields.quantity = cost.quantity;
    },

    saveForm: function () {
      return this.fields;
    },
  },
};
</script>
