<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Add a new Payment</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="cost-payment-form">
          <v-text-field
            label="Date Paid"
            v-model="fields.date"
            type="date"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Amount"
            v-model="fields.amount"
            type="number"
            prepend-inner-icon="mdi-currency-gbp"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Description"
            v-model="fields.description"
            outlined
            background-color="white"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="cost-payment-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      fields: {
        description: null,
        date: null,
        amount: null,
      },
      errors: {},
      costId: null,
      payment: {},
    };
  },

  methods: {
    openForm: function (costId, payment = null) {
      if (payment !== null) {
        this.payment = payment;
        this.isEditing = true;
        this.fields.description = payment.description;
        this.fields.date = payment.date;
        this.fields.amount = payment.amount;
      }

      this.dialog = true;
      this.costId = costId;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.fields = {
        description: null,
        date: null,
        amount: null,
      };
      this.errors = {};
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        tourId: this.$route.params.tourId,
        itineraryId: this.$route.params.itineraryId,
        costId: this.costId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.paymentId = this.payment.id;
      }

      this.$store
        .dispatch("drum/tours/saveItineraryCostPayment", {
          ...payload,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>