<template>
  <v-card outlined elevation="1">
    <v-card-title>Accommodation</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            label="Accommodation Provider"
            v-model="fields.accommodation_id"
            :items="accommodations"
            item-value="id"
            item-text="name"
            outlined
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Rate P/P Twin"
            v-model="fields.twin_rate"
            type="number"
            prepend-inner-icon="mdi-currency-gbp"
            outlined
            hide-details
            min="0.00"
            step="1.00"
            @input="updateTotal()"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Number of Guests Sharing"
            v-model="fields.no_sharing"
            type="number"
            outlined
            hide-details
            @input="updateTotal()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Rate P/P Single"
            v-model="fields.single_rate"
            type="number"
            prepend-inner-icon="mdi-currency-gbp"
            outlined
            hide-details
            min="0.00"
            step="1.00"
            @input="updateTotal()"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Number of Single Occupancy"
            v-model="fields.no_of_singles"
            type="number"
            outlined
            hide-details
            @input="updateTotal()"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Number of Nights"
            v-model="fields.no_of_nights"
            type="number"
            outlined
            min="0"
            @input="updateTotal()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    updateBalanceDue: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      fields: {
        accommodation_id: null,
        twin_rate: 0.0,
        no_sharing: 0,
        single_rate: 0.0,
        no_of_singles: 0,
        no_of_nights: 0,
      },
    };
  },

  computed: {
    accommodations() {
      return this.$store.state.drum.admin["accommodations"];
    },
  },

  methods: {
    setFields: function (cost) {
      this.fields.accommodation_id = cost.accommodation_id;
      this.fields.twin_rate = cost.twin_rate;
      this.fields.no_sharing = cost.no_sharing;
      this.fields.single_rate = cost.single_rate;
      this.fields.no_of_singles = cost.no_of_singles;
      this.fields.no_of_nights = cost.no_of_nights;
    },

    updateTotal: function () {
      if (
        ((this.fields.twin_rate && this.fields.no_sharing) ||
          (this.fields.single_rate && this.fields.no_of_singles)) &&
        this.fields.no_of_nights
      ) {
        const single = this.fields.single_rate * this.fields.no_of_singles;
        const twin = this.fields.twin_rate * this.fields.no_sharing;
        const total = (single + twin) * this.fields.no_of_nights;

        this.updateBalanceDue(total);
      }
    },

    saveForm: function () {
      return this.fields;
    },
  },
};
</script>