<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Cost</v-card-title>
        <v-card-text>Are you sure you want to delete this cost?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="reset">No, cancel</v-btn>
          <v-btn color="success" depressed :loading="loading" @click="save">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      cost: {},
    };
  },

  methods: {
    open(cost) {
      this.cost = cost;
      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      (this.loading = false), (this.cost = {});
    },

    save() {
      this.loading = true;

      this.$store
        .dispatch("drum/tours/deleteItineraryCost", {
          appId: this.$route.params.id,
          tourId: this.$route.params.tourId,
          itineraryId: this.$route.params.itineraryId,
          costId: this.cost.id,
        })
        .then(() => this.reset())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
