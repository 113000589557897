<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <v-card>
      <v-card-title class="headline">Delete Payment</v-card-title>
      <v-card-text>Are you sure you want to delete this payment?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="reset">No, cancel</v-btn>
        <v-btn color="success" depressed :loading="loading" @click="save">
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      payment: {},
    };
  },

  methods: {
    open(payment) {
      this.payment = payment;
      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      (this.loading = false), (this.payment = {});
    },

    save() {
      this.loading = true;

      this.$store
        .dispatch("drum/tours/deleteItineraryCostPayment", {
          appId: this.$route.params.id,
          tourId: this.$route.params.tourId,
          itineraryId: this.$route.params.itineraryId,
          costId: this.payment.cost_id,
          paymentId: this.payment.id,
        })
        .then(() => this.reset())
        .catch(() => (this.loading = false));
    },
  },
};
</script>