<template>
  <v-card outlined elevation="1">
    <v-card-title>Other</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Title"
            v-model="fields.title"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Single Rate"
            v-model="fields.rate"
            prepend-inner-icon="mdi-currency-gbp"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Quantity"
            v-model="fields.quantity"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        title: null,
        rate: 0,
        quantity: 0,
      },
    };
  },

  methods: {
    setFields: function (cost) {
      this.fields.title = cost.title;
      this.fields.rate = cost.rate;
      this.fields.quantity = cost.quantity;
    },

    saveForm: function () {
      return this.fields;
    },
  },
};
</script>
