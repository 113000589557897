<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card color="grey lighten-4">
      <v-card-title class="headline white"
        >{{ isEditing ? "Edit" : "Add" }} a new Cost</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-form @submit.prevent="saveForm" method="post" id="cost-form">
          <v-select
            label="Cost Type"
            :items="costTypes"
            item-value="value"
            item-text="text"
            v-model="fields.type"
            outlined
            background-color="white"
          ></v-select>
          <v-textarea
            label="Description"
            v-model="fields.description"
            rows="3"
            outlined
            background-color="white"
          ></v-textarea>
          <AccommodationForm
            ref="accommodationForm"
            v-if="fields.type === 'accommodation'"
            :updateBalanceDue="updateBalanceDue"
          />
          <CourseForm
            ref="courseForm"
            v-if="fields.type === 'golf'"
            :updateBalanceDue="updateBalanceDue"
          />
          <TransportationForm
            ref="transportationForm"
            v-if="fields.type === 'transport'"
          />
          <OtherForm ref="otherForm" v-if="fields.type === 'other'" />
          <v-card
            outlined
            elevation="1"
            class="mt-7"
            v-if="fields.type === 'accommodation' || fields.type === 'golf'"
          >
            <v-card-title>Admin</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-0">
              <v-row>
                <v-col cols="6">
                  <v-checkbox
                    label="Booking Made"
                    v-model="fields.booking_made"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Date Booking Was Made"
                    v-model="fields.date_booking_made"
                    type="date"
                    outlined
                    dense
                    hide-details
                    v-if="fields.booking_made"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-checkbox
                    label="Booking Confirmed"
                    v-model="fields.booking_confirmed"
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Date Booking Was Confirmed"
                    v-model="fields.date_booking_confirmed"
                    type="date"
                    outlined
                    dense
                    hide-details
                    v-if="fields.booking_confirmed"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Deposit Due"
                    v-model="fields.deposit_amount"
                    prepend-inner-icon="mdi-currency-gbp"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Date Deposit Due"
                    v-model="fields.date_deposit_due"
                    type="date"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Balance Due"
                    v-model="fields.balance_due"
                    prepend-inner-icon="mdi-currency-gbp"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Date Balance Paid"
                    v-model="fields.date_balance_due"
                    type="date"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="white">
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="cost-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AccommodationForm from "./AccommodationForm";
import CourseForm from "./CourseForm";
import TransportationForm from "./TransportationForm";
import OtherForm from "./OtherForm";

export default {
  components: {
    AccommodationForm,
    CourseForm,
    TransportationForm,
    OtherForm,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      costTypes: [
        { text: "Accommodation", value: "accommodation" },
        { text: "Course", value: "golf" },
        { text: "Transportation", value: "transport" },
        { text: "Other", value: "other" },
      ],
      fields: {
        type: null,
        description: null,
        booking_made: false,
        booking_confirmed: false,
        date_booking_made: null,
        date_booking_confirmed: null,
        deposit_amount: 0,
        date_deposit_due: null,
        balance_due: 0,
        date_balance_due: null,
      },
      cost: {},
    };
  },

  methods: {
    openForm: function (item = null) {
      if (item !== null) {
        this.cost = item;
        this.isEditing = true;
        this.fields.type = item.cost.type;
        this.fields.description = item.cost.description;
        this.fields.booking_made = item.cost.booking_made;
        this.fields.booking_confirmed = item.cost.booking_confirmed;
        this.fields.date_booking_made = item.cost.date_booking_made;
        this.fields.date_booking_confirmed = item.cost.date_booking_confirmed;
        this.fields.deposit_amount = item.cost.deposit_amount;
        this.fields.date_deposit_due = item.cost.date_deposit_due;
        this.fields.balance_due = item.cost.balance_due;
        this.fields.date_balance_due = item.cost.date_balance_due;

        switch (item.cost.type) {
          case "accommodation":
            this.editAccommodationCost(item.cost);
            break;
          case "golf":
            this.editCourseCost(item.cost);
            break;
          case "transport":
            this.editTransportationCost(item.cost);
            break;
          default:
            this.editOtherCost(item.cost);
        }
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.type = null;
      this.fields.description = null;
      this.cost = {};
    },

    updateBalanceDue: function (balance) {
      this.fields.balance_due = balance;
    },

    editAccommodationCost: function (cost) {
      this.$nextTick().then(() => {
        this.$refs.accommodationForm.setFields(cost);
      });
      this.editAdmin(cost);
    },

    editCourseCost: function (cost) {
      this.$nextTick().then(() => {
        this.$refs.courseForm.setFields(cost);
      });
      this.editAdmin(cost);
    },

    editTransportationCost: function (cost) {
      this.$nextTick().then(() => {
        this.$refs.transportationForm.setFields(cost);
      });
    },

    editOtherCost: function (cost) {
      this.$nextTick().then(() => {
        this.$refs.otherForm.setFields(cost);
      });
    },

    editAdmin: function (cost) {
      this.fields.booking_made = cost.booking_made;
      this.fields.booking_confirmed = cost.booking_confirmed;
      this.fields.deposit_amount = cost.deposit_amount;
      this.fields.date_deposit_due = cost.date_deposit_due;
      this.fields.balance_due = cost.balance_due;
      this.fields.date_balance_due = cost.date_balance_due;
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let fields = ((type) => {
        switch (type) {
          case "accommodation":
            return this.$refs.accommodationForm.saveForm();
          case "golf":
            return this.$refs.courseForm.saveForm();
          case "transport":
            return this.$refs.transportationForm.saveForm();
          case "other":
            return this.$refs.otherForm.saveForm();
        }
      })(this.fields.type);

      let payload = {
        appId: this.$route.params.id,
        tourId: this.$route.params.tourId,
        itineraryId: this.$route.params.itineraryId,
        isEditing: this.isEditing,
        fields: {
          ...fields,
          ...this.fields,
        },
      };

      if (this.isEditing) {
        payload.costId = this.cost.id;
      }

      this.$store
        .dispatch("drum/tours/saveItineraryCost", {
          ...payload,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>