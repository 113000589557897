<template>
  <v-card outlined elevation="1">
    <v-card-title>Course</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-autocomplete
        label="Course"
        v-model="fields.course_id"
        :items="courses"
        item-value="id"
        item-text="name"
        outlined
      ></v-autocomplete>
      <v-text-field
        label="Green Fees P/P *"
        v-model="fields.fee"
        type="number"
        prepend-inner-icon="mdi-currency-gbp"
        outlined
        @input="updateTotal()"
      ></v-text-field>
      <v-text-field
        label="Number of Golfers *"
        v-model="fields.quantity"
        type="number"
        outlined
        @input="updateTotal()"
      ></v-text-field>
      <v-text-field
        label="Total Price"
        v-model="fields.totalPrice"
        type="number"
        prepend-inner-icon="mdi-currency-gbp"
        outlined
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    updateBalanceDue: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      fields: {
        course_id: null,
        fee: null,
        quantity: null,
        totalPrice: null,
        deposit: null,
      },
    };
  },

  computed: {
    balance() {
      if (this.fields.totalPrice) {
        return this.fields.totalPrice - this.fields.deposit;
      }

      return null;
    },
    courses() {
      return this.$store.state.drum.admin["courses"];
    },
    courseId() {
      return this.fields.course_id;
    },
  },

  watch: {
    courseId() {
      if (this.fields.courseId !== null || this.fields.courseId !== "") {
        const index = this.courses.findIndex(
          (c) => c.id === this.fields.course_id
        );
        this.fields.fee = this.courses[index].default_fee;
      }
    },
  },

  methods: {
    setFields: function (cost) {
      this.fields.course_id = cost.course_id;
      this.fields.fee = cost.fee;
      this.fields.quantity = cost.quantity;
      this.fields.totalPrice = cost.balance_due;
    },

    updateTotal: function () {
      if (this.fields.fee && this.fields.quantity) {
        const total = this.fields.fee * this.fields.quantity;

        this.fields.totalPrice = total;
        this.updateBalanceDue(total);
      }
    },

    saveForm: function () {
      return this.fields;
    },
  },
};
</script>